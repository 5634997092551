/* eslint-disable @typescript-eslint/no-explicit-any */
import { Environments } from '../types/environments';

export interface UrlConfig {
	env: Environments;
	ctkUrl: string;
	cwngCourseApi: string;
	cwngStudentApi: string;
}

export function getEnvironmentUrls() {
	return (window as any).AT_STUDENT_UI_ENV as UrlConfig;
}

export function setDefaultUrls() {
	console.info(`getEnvironmentUrls: ${getEnvironmentUrls()}`);
	if (!(window as any).AT_STUDENT_UI_ENV) {
		console.info('AT_STUDENT_UI_ENV not found: setting default urls');
		(window as any).AT_STUDENT_UI_ENV = {
			env: 'dev',
			ctkUrl: 'https://edg-prod.doolittle-prod.wna.cloud/api',
			cwngStudentApi: 'https://student-api.cwng-prod.edgenuityapp.com/api',
			cwngCourseApi: 'https://course-api.cwng-prod.edgenuityapp.com/api',
		};
	}
}
